
import Chron from "./chron/chron"
import TopBar from "./top_bar";



function App() {
  
    return (
        //<Periph />
        <div >
            <TopBar />
           <Chron/>
    
        </div>
    );
}

export default App;
